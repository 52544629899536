import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";

const routes: Routes =
	[
		// welcome
		{
			path: "welcome",
			loadChildren: () => import('./pages/login/welcome/welcome.module').then(m => m.WelcomeModule),
			data: { title: 'Welcome' },
		},

		// login
		{
			path: "login",
			loadChildren: () => import('./pages/login/login/login.module').then(m => m.LoginModule),
			data: { title: 'Login' },
		},
		{
			path: "login/resetpassword",
			loadChildren: () => import('./pages/login/resetpassword/resetpassword.module').then(m => m.ResetPasswordModule),
			data: { title: 'Reset' }
		},

		// register
		{
			path: "register",
			loadChildren: () => import('./pages/login/register/register.module').then(m => m.RegisterModule),
			data: { title: 'Register' },
		},
		{
			path: "register/handshake/:userId",
			loadChildren: () => import('./pages/login/handshake/handshake.module').then(m => m.HandshakeModule),
			data: { title: 'Handshake' },
		},

		// webapp
		{
			path: "",
			loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
		},

		// not found
		{
			path: "notfound",
			loadChildren: () => import('./pages/commons/page-not-found/pagenotfound.module').then(m => m.PageNotFoundModule),
			data: { title: 'Pagina non trovata' }
		},

		// forbidden
		{
			path: "forbidden",
			loadChildren: () => import('./pages/commons/forbidden/forbidden.module').then(m => m.ForbiddenModule),
			data: { title: 'Accesso negato' }
		},

		// 404 page not found
		//----------------------------------------------------------
		{
			path: "**",
			redirectTo: "notfound"
		}
	];

const routerOptions: ExtraOptions =
{
	useHash: false,
	anchorScrolling: 'enabled',
	scrollPositionRestoration: 'enabled'
};

@NgModule({
	imports:
		[
			RouterModule.forRoot(routes, routerOptions)
		],
	exports: [RouterModule]
})
export class AppRoutingModule { }
