import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InAppNotification, InAppNotificationsCount, PushMessageRequest, PushNotificationRecipient, PushSubscription } from './notifications.models';

@Injectable()
export class NotificationsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// subscribe
	public subscribe(Subscription: PushSubscription)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/subscribe`, Subscription);
	}

	// unsubscribe
	public unsubscribe(Subscription?: PushSubscription)
	{
		if (Subscription)
			return this.http.post(`${this.baseUrl}api/shared/notifications/unsubscribe`, Subscription);
		else
			return this.http.post(`${this.baseUrl}api/shared/notifications/unsubscribe`, {});
	}

	// get subscription status
	public getSubscriptionStatus(Subscription: PushSubscription)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/getSubscriptionStatus`, Subscription);
	}

	// get push notifications recipients
	public getPushNotificationsRecipients(): Observable<Array<PushNotificationRecipient>>
	{
		return this.http.get<Array<PushNotificationRecipient>>(`${this.baseUrl}api/shared/notifications/getPushNotificationsRecipients`);
	}

	// send push message
	public sendPushMessage(PushMessage: PushMessageRequest)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/sendPushMessage`, PushMessage);
	}

	// get user notifications
	public getUserNotifications(In: boolean, Out: boolean): Observable<Array<InAppNotification>>
	{
		return this.http.get<Array<InAppNotification>>(`${this.baseUrl}api/shared/notifications/getUserNotifications?In=${In}&Out=${Out}`);
	}

	// get user notifications count
	public getUserNotificationsCount(): Observable<InAppNotificationsCount>
	{
		return this.http.get<InAppNotificationsCount>(`${this.baseUrl}api/shared/notifications/getUserNotificationsCount`);
	}

	// get notification
	public getNotification(id: string): Observable<InAppNotification>
	{
		return this.http.get<InAppNotification>(`${this.baseUrl}api/shared/notifications/getNotification?Id=${id}`);
	}

	// set notification as read
	public setNotificationAsRead(id?: string)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/setNotificationAsRead`, { id: id });
	}
}